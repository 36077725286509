<mat-dialog-content>
  <form [formGroup]="form">
    @if (form.controls.rating.enabled) {
      <ix-star-rating
        formControlName="rating"
        [label]="'Select Rating' | translate"
        [required]="true"
      ></ix-star-rating>
    }

    <ix-textarea
      formControlName="message"
      [label]="'Message' | translate"
      [rows]="5"
      [placeholder]="messagePlaceholder | translate"
    ></ix-textarea>

    <ix-checkbox
      formControlName="take_screenshot"
      [label]="'Take screenshot of the current page' | translate"
      [tooltip]="'Capture and attach screenshot to the review' | translate"
    ></ix-checkbox>

    @if (form.controls.attach_images.enabled) {
      <ix-checkbox
        formControlName="attach_images"
        [label]="'Attach additional images' | translate"
      ></ix-checkbox>
    }

    @if (form.controls.attach_images.value) {
      <ix-file-input
        formControlName="images"
        class="attach-images"
        [label]="'Attach images (optional)' | translate"
        [tooltip]="'Select images you want attach to review' | translate"
        [multiple]="true"
        [acceptedFiles]="acceptedFiles"
      ></ix-file-input>
    }
  </form>

  @if (!(isEnterprise$ | async)) {
    <p [innerHTML]="voteForNewFeaturesText"></p>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <ix-form-actions>
    <button
      mat-button
      type="submit"
      color="primary"
      ixTest="submit"
      [disabled]="form.invalid || isLoading"
      (click)="onSubmit()"
    >
      {{ 'Submit' | translate }}
    </button>
  </ix-form-actions>
</mat-dialog-actions>
