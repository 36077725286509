<h1 #errorTitle mat-dialog-title id="err-title" class="err-title">
  <ix-icon class="error-warning-icon" name="error"></ix-icon>
  {{ title | translate }}
</h1>
<div #errorMdContent id="err-md-content">
  <div #errorMessageWrapper id="err-message-wrapper"><span [innerHTML]="message"></span></div>
  @if (backtrace) {
    <div
      class="more-info"
      (click)="toggleOpen()"
    >
      @if (isCloseMoreInfo) {
        <ix-icon name="add_circle_outline"></ix-icon>
      } @else {
        <ix-icon name="remove_circle_outline"></ix-icon>
      }
      <span>{{ 'More info...' | translate }}</span>
    </div>
  }
  @if (backtrace) {
    <div
      #errorBtPanel
      id="err-bt-panel"
      class="backtrace-panel"
      [class.open]="!isCloseMoreInfo"
    >
      <div #errorBtText class="textarea scrolled-down" id="err-bt-text" readonly matInput>
        {{ 'Error' | translate }}: {{ backtrace }}
      </div>
    </div>
  }
</div>
@if (logs) {
  <button
    mat-button
    class="mat-mdc-button mat-button mat-primary"
    ixTest="download-logs"
    (click)="downloadLogs()"
  >
    {{ 'Download Logs' | translate }}
  </button>
}
