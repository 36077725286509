!function (t, r) {
  "object" == typeof exports && "object" == typeof module ? module.exports = r() : "function" == typeof define && define.amd ? define([], r) : "object" == typeof exports ? exports.MessageFormat = r() : t.MessageFormat = r();
}(this, function () {
  return function (t) {
    var r = {};
    function e(n) {
      if (r[n]) return r[n].exports;
      var o = r[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return t[n].call(o.exports, o, o.exports, e), o.l = !0, o.exports;
    }
    return e.m = t, e.c = r, e.d = function (t, r, n) {
      e.o(t, r) || Object.defineProperty(t, r, {
        enumerable: !0,
        get: n
      });
    }, e.r = function (t) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(t, "__esModule", {
        value: !0
      });
    }, e.t = function (t, r) {
      if (1 & r && (t = e(t)), 8 & r) return t;
      if (4 & r && "object" == typeof t && t && t.__esModule) return t;
      var n = Object.create(null);
      if (e.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: t
      }), 2 & r && "string" != typeof t) for (var o in t) e.d(n, o, function (r) {
        return t[r];
      }.bind(null, o));
      return n;
    }, e.n = function (t) {
      var r = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };
      return e.d(r, "a", r), r;
    }, e.o = function (t, r) {
      return Object.prototype.hasOwnProperty.call(t, r);
    }, e.p = "", e(e.s = 8);
  }([function (t, r, e) {
    var n, o;
    void 0 === (o = "function" == typeof (n = {
      af: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ak: function (t, r) {
        return r ? "other" : 0 == t || 1 == t ? "one" : "other";
      },
      am: function (t, r) {
        return r ? "other" : t >= 0 && t <= 1 ? "one" : "other";
      },
      ar: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t,
          o = n && e[0].slice(-2);
        return r ? "other" : 0 == t ? "zero" : 1 == t ? "one" : 2 == t ? "two" : o >= 3 && o <= 10 ? "few" : o >= 11 && o <= 99 ? "many" : "other";
      },
      ars: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t,
          o = n && e[0].slice(-2);
        return r ? "other" : 0 == t ? "zero" : 1 == t ? "one" : 2 == t ? "two" : o >= 3 && o <= 10 ? "few" : o >= 11 && o <= 99 ? "many" : "other";
      },
      as: function (t, r) {
        return r ? 1 == t || 5 == t || 7 == t || 8 == t || 9 == t || 10 == t ? "one" : 2 == t || 3 == t ? "two" : 4 == t ? "few" : 6 == t ? "many" : "other" : t >= 0 && t <= 1 ? "one" : "other";
      },
      asa: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ast: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      az: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = n.slice(-1),
          i = n.slice(-2),
          u = n.slice(-3);
        return r ? 1 == o || 2 == o || 5 == o || 7 == o || 8 == o || 20 == i || 50 == i || 70 == i || 80 == i ? "one" : 3 == o || 4 == o || 100 == u || 200 == u || 300 == u || 400 == u || 500 == u || 600 == u || 700 == u || 800 == u || 900 == u ? "few" : 0 == n || 6 == o || 40 == i || 60 == i || 90 == i ? "many" : "other" : 1 == t ? "one" : "other";
      },
      be: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t,
          o = n && e[0].slice(-1),
          i = n && e[0].slice(-2);
        return r ? 2 != o && 3 != o || 12 == i || 13 == i ? "other" : "few" : 1 == o && 11 != i ? "one" : o >= 2 && o <= 4 && (i < 12 || i > 14) ? "few" : n && 0 == o || o >= 5 && o <= 9 || i >= 11 && i <= 14 ? "many" : "other";
      },
      bem: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      bez: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      bg: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      bh: function (t, r) {
        return r ? "other" : 0 == t || 1 == t ? "one" : "other";
      },
      bm: function (t, r) {
        return "other";
      },
      bn: function (t, r) {
        return r ? 1 == t || 5 == t || 7 == t || 8 == t || 9 == t || 10 == t ? "one" : 2 == t || 3 == t ? "two" : 4 == t ? "few" : 6 == t ? "many" : "other" : t >= 0 && t <= 1 ? "one" : "other";
      },
      bo: function (t, r) {
        return "other";
      },
      br: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t,
          o = n && e[0].slice(-1),
          i = n && e[0].slice(-2),
          u = n && e[0].slice(-6);
        return r ? "other" : 1 == o && 11 != i && 71 != i && 91 != i ? "one" : 2 == o && 12 != i && 72 != i && 92 != i ? "two" : (3 == o || 4 == o || 9 == o) && (i < 10 || i > 19) && (i < 70 || i > 79) && (i < 90 || i > 99) ? "few" : 0 != t && n && 0 == u ? "many" : "other";
      },
      brx: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      bs: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "",
          i = !e[1],
          u = n.slice(-1),
          a = n.slice(-2),
          c = o.slice(-1),
          h = o.slice(-2);
        return r ? "other" : i && 1 == u && 11 != a || 1 == c && 11 != h ? "one" : i && u >= 2 && u <= 4 && (a < 12 || a > 14) || c >= 2 && c <= 4 && (h < 12 || h > 14) ? "few" : "other";
      },
      ca: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? 1 == t || 3 == t ? "one" : 2 == t ? "two" : 4 == t ? "few" : "other" : 1 == t && n ? "one" : "other";
      },
      ce: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      cgg: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      chr: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ckb: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      cs: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = !e[1];
        return r ? "other" : 1 == t && o ? "one" : n >= 2 && n <= 4 && o ? "few" : o ? "other" : "many";
      },
      cy: function (t, r) {
        return r ? 0 == t || 7 == t || 8 == t || 9 == t ? "zero" : 1 == t ? "one" : 2 == t ? "two" : 3 == t || 4 == t ? "few" : 5 == t || 6 == t ? "many" : "other" : 0 == t ? "zero" : 1 == t ? "one" : 2 == t ? "two" : 3 == t ? "few" : 6 == t ? "many" : "other";
      },
      da: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = Number(e[0]) == t;
        return r ? "other" : 1 != t && (o || 0 != n && 1 != n) ? "other" : "one";
      },
      de: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      dsb: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "",
          i = !e[1],
          u = n.slice(-2),
          a = o.slice(-2);
        return r ? "other" : i && 1 == u || 1 == a ? "one" : i && 2 == u || 2 == a ? "two" : i && (3 == u || 4 == u) || 3 == a || 4 == a ? "few" : "other";
      },
      dv: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      dz: function (t, r) {
        return "other";
      },
      ee: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      el: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      en: function (t, r) {
        var e = String(t).split("."),
          n = !e[1],
          o = Number(e[0]) == t,
          i = o && e[0].slice(-1),
          u = o && e[0].slice(-2);
        return r ? 1 == i && 11 != u ? "one" : 2 == i && 12 != u ? "two" : 3 == i && 13 != u ? "few" : "other" : 1 == t && n ? "one" : "other";
      },
      eo: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      es: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      et: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      eu: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      fa: function (t, r) {
        return r ? "other" : t >= 0 && t <= 1 ? "one" : "other";
      },
      ff: function (t, r) {
        return r ? "other" : t >= 0 && t < 2 ? "one" : "other";
      },
      fi: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      fil: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "",
          i = !e[1],
          u = n.slice(-1),
          a = o.slice(-1);
        return r ? 1 == t ? "one" : "other" : i && (1 == n || 2 == n || 3 == n) || i && 4 != u && 6 != u && 9 != u || !i && 4 != a && 6 != a && 9 != a ? "one" : "other";
      },
      fo: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      fr: function (t, r) {
        return r ? 1 == t ? "one" : "other" : t >= 0 && t < 2 ? "one" : "other";
      },
      fur: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      fy: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      ga: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t;
        return r ? 1 == t ? "one" : "other" : 1 == t ? "one" : 2 == t ? "two" : n && t >= 3 && t <= 6 ? "few" : n && t >= 7 && t <= 10 ? "many" : "other";
      },
      gd: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t;
        return r ? 1 == t || 11 == t ? "one" : 2 == t || 12 == t ? "two" : 3 == t || 13 == t ? "few" : "other" : 1 == t || 11 == t ? "one" : 2 == t || 12 == t ? "two" : n && t >= 3 && t <= 10 || n && t >= 13 && t <= 19 ? "few" : "other";
      },
      gl: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      gsw: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      gu: function (t, r) {
        return r ? 1 == t ? "one" : 2 == t || 3 == t ? "two" : 4 == t ? "few" : 6 == t ? "many" : "other" : t >= 0 && t <= 1 ? "one" : "other";
      },
      guw: function (t, r) {
        return r ? "other" : 0 == t || 1 == t ? "one" : "other";
      },
      gv: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = !e[1],
          i = n.slice(-1),
          u = n.slice(-2);
        return r ? "other" : o && 1 == i ? "one" : o && 2 == i ? "two" : !o || 0 != u && 20 != u && 40 != u && 60 != u && 80 != u ? o ? "other" : "many" : "few";
      },
      ha: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      haw: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      he: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = !e[1],
          i = Number(e[0]) == t,
          u = i && e[0].slice(-1);
        return r ? "other" : 1 == t && o ? "one" : 2 == n && o ? "two" : o && (t < 0 || t > 10) && i && 0 == u ? "many" : "other";
      },
      hi: function (t, r) {
        return r ? 1 == t ? "one" : 2 == t || 3 == t ? "two" : 4 == t ? "few" : 6 == t ? "many" : "other" : t >= 0 && t <= 1 ? "one" : "other";
      },
      hr: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "",
          i = !e[1],
          u = n.slice(-1),
          a = n.slice(-2),
          c = o.slice(-1),
          h = o.slice(-2);
        return r ? "other" : i && 1 == u && 11 != a || 1 == c && 11 != h ? "one" : i && u >= 2 && u <= 4 && (a < 12 || a > 14) || c >= 2 && c <= 4 && (h < 12 || h > 14) ? "few" : "other";
      },
      hsb: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "",
          i = !e[1],
          u = n.slice(-2),
          a = o.slice(-2);
        return r ? "other" : i && 1 == u || 1 == a ? "one" : i && 2 == u || 2 == a ? "two" : i && (3 == u || 4 == u) || 3 == a || 4 == a ? "few" : "other";
      },
      hu: function (t, r) {
        return r ? 1 == t || 5 == t ? "one" : "other" : 1 == t ? "one" : "other";
      },
      hy: function (t, r) {
        return r ? 1 == t ? "one" : "other" : t >= 0 && t < 2 ? "one" : "other";
      },
      ia: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      id: function (t, r) {
        return "other";
      },
      ig: function (t, r) {
        return "other";
      },
      ii: function (t, r) {
        return "other";
      },
      in: function (t, r) {
        return "other";
      },
      io: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      is: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = Number(e[0]) == t,
          i = n.slice(-1),
          u = n.slice(-2);
        return r ? "other" : o && 1 == i && 11 != u || !o ? "one" : "other";
      },
      it: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? 11 == t || 8 == t || 80 == t || 800 == t ? "many" : "other" : 1 == t && n ? "one" : "other";
      },
      iu: function (t, r) {
        return r ? "other" : 1 == t ? "one" : 2 == t ? "two" : "other";
      },
      iw: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = !e[1],
          i = Number(e[0]) == t,
          u = i && e[0].slice(-1);
        return r ? "other" : 1 == t && o ? "one" : 2 == n && o ? "two" : o && (t < 0 || t > 10) && i && 0 == u ? "many" : "other";
      },
      ja: function (t, r) {
        return "other";
      },
      jbo: function (t, r) {
        return "other";
      },
      jgo: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ji: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      jmc: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      jv: function (t, r) {
        return "other";
      },
      jw: function (t, r) {
        return "other";
      },
      ka: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = n.slice(-2);
        return r ? 1 == n ? "one" : 0 == n || o >= 2 && o <= 20 || 40 == o || 60 == o || 80 == o ? "many" : "other" : 1 == t ? "one" : "other";
      },
      kab: function (t, r) {
        return r ? "other" : t >= 0 && t < 2 ? "one" : "other";
      },
      kaj: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      kcg: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      kde: function (t, r) {
        return "other";
      },
      kea: function (t, r) {
        return "other";
      },
      kk: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t,
          o = n && e[0].slice(-1);
        return r ? 6 == o || 9 == o || n && 0 == o && 0 != t ? "many" : "other" : 1 == t ? "one" : "other";
      },
      kkj: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      kl: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      km: function (t, r) {
        return "other";
      },
      kn: function (t, r) {
        return r ? "other" : t >= 0 && t <= 1 ? "one" : "other";
      },
      ko: function (t, r) {
        return "other";
      },
      ks: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ksb: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ksh: function (t, r) {
        return r ? "other" : 0 == t ? "zero" : 1 == t ? "one" : "other";
      },
      ku: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      kw: function (t, r) {
        return r ? "other" : 1 == t ? "one" : 2 == t ? "two" : "other";
      },
      ky: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      lag: function (t, r) {
        var e = String(t).split("."),
          n = e[0];
        return r ? "other" : 0 == t ? "zero" : 0 != n && 1 != n || 0 == t ? "other" : "one";
      },
      lb: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      lg: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      lkt: function (t, r) {
        return "other";
      },
      ln: function (t, r) {
        return r ? "other" : 0 == t || 1 == t ? "one" : "other";
      },
      lo: function (t, r) {
        return r && 1 == t ? "one" : "other";
      },
      lt: function (t, r) {
        var e = String(t).split("."),
          n = e[1] || "",
          o = Number(e[0]) == t,
          i = o && e[0].slice(-1),
          u = o && e[0].slice(-2);
        return r ? "other" : 1 == i && (u < 11 || u > 19) ? "one" : i >= 2 && i <= 9 && (u < 11 || u > 19) ? "few" : 0 != n ? "many" : "other";
      },
      lv: function (t, r) {
        var e = String(t).split("."),
          n = e[1] || "",
          o = n.length,
          i = Number(e[0]) == t,
          u = i && e[0].slice(-1),
          a = i && e[0].slice(-2),
          c = n.slice(-2),
          h = n.slice(-1);
        return r ? "other" : i && 0 == u || a >= 11 && a <= 19 || 2 == o && c >= 11 && c <= 19 ? "zero" : 1 == u && 11 != a || 2 == o && 1 == h && 11 != c || 2 != o && 1 == h ? "one" : "other";
      },
      mas: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      mg: function (t, r) {
        return r ? "other" : 0 == t || 1 == t ? "one" : "other";
      },
      mgo: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      mk: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "",
          i = !e[1],
          u = n.slice(-1),
          a = n.slice(-2),
          c = o.slice(-1),
          h = o.slice(-2);
        return r ? 1 == u && 11 != a ? "one" : 2 == u && 12 != a ? "two" : 7 != u && 8 != u || 17 == a || 18 == a ? "other" : "many" : i && 1 == u && 11 != a || 1 == c && 11 != h ? "one" : "other";
      },
      ml: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      mn: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      mo: function (t, r) {
        var e = String(t).split("."),
          n = !e[1],
          o = Number(e[0]) == t,
          i = o && e[0].slice(-2);
        return r ? 1 == t ? "one" : "other" : 1 == t && n ? "one" : !n || 0 == t || 1 != t && i >= 1 && i <= 19 ? "few" : "other";
      },
      mr: function (t, r) {
        return r ? 1 == t ? "one" : 2 == t || 3 == t ? "two" : 4 == t ? "few" : "other" : t >= 0 && t <= 1 ? "one" : "other";
      },
      ms: function (t, r) {
        return r && 1 == t ? "one" : "other";
      },
      mt: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t,
          o = n && e[0].slice(-2);
        return r ? "other" : 1 == t ? "one" : 0 == t || o >= 2 && o <= 10 ? "few" : o >= 11 && o <= 19 ? "many" : "other";
      },
      my: function (t, r) {
        return "other";
      },
      nah: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      naq: function (t, r) {
        return r ? "other" : 1 == t ? "one" : 2 == t ? "two" : "other";
      },
      nb: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      nd: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ne: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t;
        return r ? n && t >= 1 && t <= 4 ? "one" : "other" : 1 == t ? "one" : "other";
      },
      nl: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      nn: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      nnh: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      no: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      nqo: function (t, r) {
        return "other";
      },
      nr: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      nso: function (t, r) {
        return r ? "other" : 0 == t || 1 == t ? "one" : "other";
      },
      ny: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      nyn: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      om: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      or: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t;
        return r ? 1 == t || 5 == t || n && t >= 7 && t <= 9 ? "one" : 2 == t || 3 == t ? "two" : 4 == t ? "few" : 6 == t ? "many" : "other" : 1 == t ? "one" : "other";
      },
      os: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      pa: function (t, r) {
        return r ? "other" : 0 == t || 1 == t ? "one" : "other";
      },
      pap: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      pl: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = !e[1],
          i = n.slice(-1),
          u = n.slice(-2);
        return r ? "other" : 1 == t && o ? "one" : o && i >= 2 && i <= 4 && (u < 12 || u > 14) ? "few" : o && 1 != n && (0 == i || 1 == i) || o && i >= 5 && i <= 9 || o && u >= 12 && u <= 14 ? "many" : "other";
      },
      prg: function (t, r) {
        var e = String(t).split("."),
          n = e[1] || "",
          o = n.length,
          i = Number(e[0]) == t,
          u = i && e[0].slice(-1),
          a = i && e[0].slice(-2),
          c = n.slice(-2),
          h = n.slice(-1);
        return r ? "other" : i && 0 == u || a >= 11 && a <= 19 || 2 == o && c >= 11 && c <= 19 ? "zero" : 1 == u && 11 != a || 2 == o && 1 == h && 11 != c || 2 != o && 1 == h ? "one" : "other";
      },
      ps: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      pt: function (t, r) {
        var e = String(t).split("."),
          n = e[0];
        return r ? "other" : 0 == n || 1 == n ? "one" : "other";
      },
      "pt-PT": function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      rm: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ro: function (t, r) {
        var e = String(t).split("."),
          n = !e[1],
          o = Number(e[0]) == t,
          i = o && e[0].slice(-2);
        return r ? 1 == t ? "one" : "other" : 1 == t && n ? "one" : !n || 0 == t || 1 != t && i >= 1 && i <= 19 ? "few" : "other";
      },
      rof: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      root: function (t, r) {
        return "other";
      },
      ru: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = !e[1],
          i = n.slice(-1),
          u = n.slice(-2);
        return r ? "other" : o && 1 == i && 11 != u ? "one" : o && i >= 2 && i <= 4 && (u < 12 || u > 14) ? "few" : o && 0 == i || o && i >= 5 && i <= 9 || o && u >= 11 && u <= 14 ? "many" : "other";
      },
      rwk: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      sah: function (t, r) {
        return "other";
      },
      saq: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      sc: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? 11 == t || 8 == t || 80 == t || 800 == t ? "many" : "other" : 1 == t && n ? "one" : "other";
      },
      scn: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? 11 == t || 8 == t || 80 == t || 800 == t ? "many" : "other" : 1 == t && n ? "one" : "other";
      },
      sd: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      sdh: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      se: function (t, r) {
        return r ? "other" : 1 == t ? "one" : 2 == t ? "two" : "other";
      },
      seh: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ses: function (t, r) {
        return "other";
      },
      sg: function (t, r) {
        return "other";
      },
      sh: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "",
          i = !e[1],
          u = n.slice(-1),
          a = n.slice(-2),
          c = o.slice(-1),
          h = o.slice(-2);
        return r ? "other" : i && 1 == u && 11 != a || 1 == c && 11 != h ? "one" : i && u >= 2 && u <= 4 && (a < 12 || a > 14) || c >= 2 && c <= 4 && (h < 12 || h > 14) ? "few" : "other";
      },
      shi: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t;
        return r ? "other" : t >= 0 && t <= 1 ? "one" : n && t >= 2 && t <= 10 ? "few" : "other";
      },
      si: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "";
        return r ? "other" : 0 == t || 1 == t || 0 == n && 1 == o ? "one" : "other";
      },
      sk: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = !e[1];
        return r ? "other" : 1 == t && o ? "one" : n >= 2 && n <= 4 && o ? "few" : o ? "other" : "many";
      },
      sl: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = !e[1],
          i = n.slice(-2);
        return r ? "other" : o && 1 == i ? "one" : o && 2 == i ? "two" : o && (3 == i || 4 == i) || !o ? "few" : "other";
      },
      sma: function (t, r) {
        return r ? "other" : 1 == t ? "one" : 2 == t ? "two" : "other";
      },
      smi: function (t, r) {
        return r ? "other" : 1 == t ? "one" : 2 == t ? "two" : "other";
      },
      smj: function (t, r) {
        return r ? "other" : 1 == t ? "one" : 2 == t ? "two" : "other";
      },
      smn: function (t, r) {
        return r ? "other" : 1 == t ? "one" : 2 == t ? "two" : "other";
      },
      sms: function (t, r) {
        return r ? "other" : 1 == t ? "one" : 2 == t ? "two" : "other";
      },
      sn: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      so: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      sq: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t,
          o = n && e[0].slice(-1),
          i = n && e[0].slice(-2);
        return r ? 1 == t ? "one" : 4 == o && 14 != i ? "many" : "other" : 1 == t ? "one" : "other";
      },
      sr: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "",
          i = !e[1],
          u = n.slice(-1),
          a = n.slice(-2),
          c = o.slice(-1),
          h = o.slice(-2);
        return r ? "other" : i && 1 == u && 11 != a || 1 == c && 11 != h ? "one" : i && u >= 2 && u <= 4 && (a < 12 || a > 14) || c >= 2 && c <= 4 && (h < 12 || h > 14) ? "few" : "other";
      },
      ss: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ssy: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      st: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      sv: function (t, r) {
        var e = String(t).split("."),
          n = !e[1],
          o = Number(e[0]) == t,
          i = o && e[0].slice(-1),
          u = o && e[0].slice(-2);
        return r ? 1 != i && 2 != i || 11 == u || 12 == u ? "other" : "one" : 1 == t && n ? "one" : "other";
      },
      sw: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      syr: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ta: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      te: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      teo: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      th: function (t, r) {
        return "other";
      },
      ti: function (t, r) {
        return r ? "other" : 0 == t || 1 == t ? "one" : "other";
      },
      tig: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      tk: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t,
          o = n && e[0].slice(-1);
        return r ? 6 == o || 9 == o || 10 == t ? "few" : "other" : 1 == t ? "one" : "other";
      },
      tl: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = e[1] || "",
          i = !e[1],
          u = n.slice(-1),
          a = o.slice(-1);
        return r ? 1 == t ? "one" : "other" : i && (1 == n || 2 == n || 3 == n) || i && 4 != u && 6 != u && 9 != u || !i && 4 != a && 6 != a && 9 != a ? "one" : "other";
      },
      tn: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      to: function (t, r) {
        return "other";
      },
      tr: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ts: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      tzm: function (t, r) {
        var e = String(t).split("."),
          n = Number(e[0]) == t;
        return r ? "other" : 0 == t || 1 == t || n && t >= 11 && t <= 99 ? "one" : "other";
      },
      ug: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      uk: function (t, r) {
        var e = String(t).split("."),
          n = e[0],
          o = !e[1],
          i = Number(e[0]) == t,
          u = i && e[0].slice(-1),
          a = i && e[0].slice(-2),
          c = n.slice(-1),
          h = n.slice(-2);
        return r ? 3 == u && 13 != a ? "few" : "other" : o && 1 == c && 11 != h ? "one" : o && c >= 2 && c <= 4 && (h < 12 || h > 14) ? "few" : o && 0 == c || o && c >= 5 && c <= 9 || o && h >= 11 && h <= 14 ? "many" : "other";
      },
      ur: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      uz: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      ve: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      vi: function (t, r) {
        return r && 1 == t ? "one" : "other";
      },
      vo: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      vun: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      wa: function (t, r) {
        return r ? "other" : 0 == t || 1 == t ? "one" : "other";
      },
      wae: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      wo: function (t, r) {
        return "other";
      },
      xh: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      xog: function (t, r) {
        return r ? "other" : 1 == t ? "one" : "other";
      },
      yi: function (t, r) {
        var e = String(t).split("."),
          n = !e[1];
        return r ? "other" : 1 == t && n ? "one" : "other";
      },
      yo: function (t, r) {
        return "other";
      },
      yue: function (t, r) {
        return "other";
      },
      zh: function (t, r) {
        return "other";
      },
      zu: function (t, r) {
        return r ? "other" : t >= 0 && t <= 1 ? "one" : "other";
      }
    }) ? n.call(r, e, r, t) : n) || (t.exports = o);
  }, function (t, r, e) {
    t.exports = {
      date: e(4),
      duration: e(5),
      number: e(6),
      time: e(7)
    };
  }, function (t, r, e) {
    "use strict";

    function n(t, r, e, o) {
      this.message = t, this.expected = r, this.found = e, this.location = o, this.name = "SyntaxError", "function" == typeof Error.captureStackTrace && Error.captureStackTrace(this, n);
    }
    !function (t, r) {
      function e() {
        this.constructor = t;
      }
      e.prototype = r.prototype, t.prototype = new e();
    }(n, Error), n.buildMessage = function (t, r) {
      var e = {
        literal: function (t) {
          return '"' + o(t.text) + '"';
        },
        class: function (t) {
          var r,
            e = "";
          for (r = 0; r < t.parts.length; r++) e += t.parts[r] instanceof Array ? i(t.parts[r][0]) + "-" + i(t.parts[r][1]) : i(t.parts[r]);
          return "[" + (t.inverted ? "^" : "") + e + "]";
        },
        any: function (t) {
          return "any character";
        },
        end: function (t) {
          return "end of input";
        },
        other: function (t) {
          return t.description;
        }
      };
      function n(t) {
        return t.charCodeAt(0).toString(16).toUpperCase();
      }
      function o(t) {
        return t.replace(/\\/g, "\\\\").replace(/"/g, '\\"').replace(/\0/g, "\\0").replace(/\t/g, "\\t").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/[\x00-\x0F]/g, function (t) {
          return "\\x0" + n(t);
        }).replace(/[\x10-\x1F\x7F-\x9F]/g, function (t) {
          return "\\x" + n(t);
        });
      }
      function i(t) {
        return t.replace(/\\/g, "\\\\").replace(/\]/g, "\\]").replace(/\^/g, "\\^").replace(/-/g, "\\-").replace(/\0/g, "\\0").replace(/\t/g, "\\t").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/[\x00-\x0F]/g, function (t) {
          return "\\x0" + n(t);
        }).replace(/[\x10-\x1F\x7F-\x9F]/g, function (t) {
          return "\\x" + n(t);
        });
      }
      return "Expected " + function (t) {
        var r,
          n,
          o,
          i = new Array(t.length);
        for (r = 0; r < t.length; r++) i[r] = (o = t[r], e[o.type](o));
        if (i.sort(), i.length > 0) {
          for (r = 1, n = 1; r < i.length; r++) i[r - 1] !== i[r] && (i[n] = i[r], n++);
          i.length = n;
        }
        switch (i.length) {
          case 1:
            return i[0];
          case 2:
            return i[0] + " or " + i[1];
          default:
            return i.slice(0, -1).join(", ") + ", or " + i[i.length - 1];
        }
      }(t) + " but " + function (t) {
        return t ? '"' + o(t) + '"' : "end of input";
      }(r) + " found.";
    }, t.exports = {
      SyntaxError: n,
      parse: function (t, r) {
        r = void 0 !== r ? r : {};
        var e,
          o = {},
          i = {
            start: tr
          },
          u = tr,
          a = "#",
          c = It("#", !1),
          h = function () {
            return pr[0];
          },
          f = function () {
            return {
              type: "octothorpe"
            };
          },
          s = function (t) {
            return t.join("");
          },
          l = "{",
          p = It("{", !1),
          m = "}",
          d = It("}", !1),
          y = function (t) {
            return {
              type: "argument",
              arg: t
            };
          },
          g = ",",
          v = It(",", !1),
          w = "select",
          b = It("select", !1),
          S = function (t, e) {
            return r.strict && pr.unshift(!1), e;
          },
          k = function (t, e) {
            return r.strict && pr.shift(), {
              type: "select",
              arg: t,
              cases: e
            };
          },
          x = "plural",
          A = It("plural", !1),
          j = "selectordinal",
          N = It("selectordinal", !1),
          C = function (t, r) {
            return pr.unshift(!0), r;
          },
          F = function (t, e, n, o) {
            var i = ("selectordinal" === e ? r.ordinal : r.cardinal) || ["zero", "one", "two", "few", "many", "other"];
            return i && i.length && o.forEach(function (r) {
              if (isNaN(r.key) && i.indexOf(r.key) < 0) throw new Error("Invalid key `" + r.key + "` for argument `" + t + "`. Valid " + e + " keys for this locale are `" + i.join("`, `") + "`, and explicit keys like `=0`.");
            }), pr.shift(), {
              type: e,
              arg: t,
              offset: n || 0,
              cases: o
            };
          },
          O = function (t, r, e) {
            return {
              type: "function",
              arg: t,
              key: r,
              param: e
            };
          },
          E = Vt("identifier"),
          z = /^[^\t-\r \x85\u200E\u200F\u2028\u2029!-\/:-@[-\^`{-~\xA1-\xA7\xA9\xAB\xAC\xAE\xB0\xB1\xB6\xBB\xBF\xD7\xF7\u2010-\u2027\u2030-\u203E\u2041-\u2053\u2055-\u205E\u2190-\u245F\u2500-\u2775\u2794-\u2BFF\u2E00-\u2E7F\u3001-\u3003\u3008-\u3020\u3030\uFD3E\uFD3F\uFE45\uFE46]/,
          P = Yt([["\t", "\r"], " ", "", "‎", "‏", "\u2028", "\u2029", ["!", "/"], [":", "@"], ["[", "^"], "`", ["{", "~"], ["¡", "§"], "©", "«", "¬", "®", "°", "±", "¶", "»", "¿", "×", "÷", ["‐", "‧"], ["‰", "‾"], ["⁁", "⁓"], ["⁕", "⁞"], ["←", "⑟"], ["─", "❵"], ["➔", "⯿"], ["⸀", "⹿"], ["、", "〃"], ["〈", "〠"], "〰", "﴾", "﴿", "﹅", "﹆"], !0, !1),
          L = function (t, r) {
            return {
              key: t,
              tokens: r
            };
          },
          J = function (t) {
            return t;
          },
          D = Vt("plural offset"),
          M = "offset",
          _ = It("offset", !1),
          T = ":",
          R = It(":", !1),
          B = function (t) {
            return t;
          },
          q = "=",
          $ = It("=", !1),
          K = "number",
          G = It("number", !1),
          U = "date",
          Z = It("date", !1),
          I = "time",
          Y = It("time", !1),
          V = "spellout",
          W = It("spellout", !1),
          H = "ordinal",
          Q = It("ordinal", !1),
          X = "duration",
          tt = It("duration", !1),
          rt = function (t) {
            if (r.strict || /^\d/.test(t)) return !1;
            switch (t.toLowerCase()) {
              case "select":
              case "plural":
              case "selectordinal":
                return !1;
              default:
                return !0;
            }
          },
          et = function (t) {
            return t;
          },
          nt = function (t) {
            return !r.strict;
          },
          ot = function (t) {
            return {
              tokens: t
            };
          },
          it = function (t) {
            return {
              tokens: [t.join("")]
            };
          },
          ut = Vt("a valid (strict) function parameter"),
          at = /^[^'{}]/,
          ct = Yt(["'", "{", "}"], !0, !1),
          ht = function (t) {
            return t.join("");
          },
          ft = "'",
          st = It("'", !1),
          lt = function (t) {
            return t;
          },
          pt = function (t) {
            return "{" + t.join("") + "}";
          },
          mt = Vt("doubled apostrophe"),
          dt = "''",
          yt = It("''", !1),
          gt = function () {
            return "'";
          },
          vt = /^[^']/,
          wt = Yt(["'"], !0, !1),
          bt = "'{",
          St = It("'{", !1),
          kt = function (t) {
            return "{" + t.join("");
          },
          xt = "'}",
          At = It("'}", !1),
          jt = function (t) {
            return "}" + t.join("");
          },
          Nt = Vt("escaped string"),
          Ct = "'#",
          Ft = It("'#", !1),
          Ot = function (t) {
            return "#" + t.join("");
          },
          Et = function (t) {
            return t[0];
          },
          zt = Vt("plain char"),
          Pt = /^[^{}#\0-\x08\x0E-\x1F\x7F]/,
          Lt = Yt(["{", "}", "#", ["\0", "\b"], ["", ""], ""], !0, !1),
          Jt = function (t) {
            return !pr[0];
          },
          Dt = function (t) {
            return t;
          },
          Mt = Vt("integer"),
          _t = /^[0-9]/,
          Tt = Yt([["0", "9"]], !1, !1),
          Rt = Vt("white space"),
          Bt = /^[\t-\r \x85\u200E\u200F\u2028\u2029]/,
          qt = Yt([["\t", "\r"], " ", "", "‎", "‏", "\u2028", "\u2029"], !1, !1),
          $t = 0,
          Kt = [{
            line: 1,
            column: 1
          }],
          Gt = 0,
          Ut = [],
          Zt = 0;
        if ("startRule" in r) {
          if (!(r.startRule in i)) throw new Error("Can't start parsing from rule \"" + r.startRule + '".');
          u = i[r.startRule];
        }
        function It(t, r) {
          return {
            type: "literal",
            text: t,
            ignoreCase: r
          };
        }
        function Yt(t, r, e) {
          return {
            type: "class",
            parts: t,
            inverted: r,
            ignoreCase: e
          };
        }
        function Vt(t) {
          return {
            type: "other",
            description: t
          };
        }
        function Wt(r) {
          var e,
            n = Kt[r];
          if (n) return n;
          for (e = r - 1; !Kt[e];) e--;
          for (n = {
            line: (n = Kt[e]).line,
            column: n.column
          }; e < r;) 10 === t.charCodeAt(e) ? (n.line++, n.column = 1) : n.column++, e++;
          return Kt[r] = n, n;
        }
        function Ht(t, r) {
          var e = Wt(t),
            n = Wt(r);
          return {
            start: {
              offset: t,
              line: e.line,
              column: e.column
            },
            end: {
              offset: r,
              line: n.line,
              column: n.column
            }
          };
        }
        function Qt(t) {
          $t < Gt || ($t > Gt && (Gt = $t, Ut = []), Ut.push(t));
        }
        function Xt(t, r, e) {
          return new n(n.buildMessage(t, r), t, r, e);
        }
        function tr() {
          var t, r;
          for (t = [], r = rr(); r !== o;) t.push(r), r = rr();
          return t;
        }
        function rr() {
          var r, e, n;
          if ((r = function () {
            var r, e, n, i;
            return r = $t, 123 === t.charCodeAt($t) ? (e = l, $t++) : (e = o, 0 === Zt && Qt(p)), e !== o && lr() !== o && (n = er()) !== o && lr() !== o ? (125 === t.charCodeAt($t) ? (i = m, $t++) : (i = o, 0 === Zt && Qt(d)), i !== o ? (e = y(n), r = e) : ($t = r, r = o)) : ($t = r, r = o), r;
          }()) === o && (r = function () {
            var r, e, n, i, u, a, c, h, f;
            if (r = $t, 123 === t.charCodeAt($t) ? (e = l, $t++) : (e = o, 0 === Zt && Qt(p)), e !== o) {
              if (lr() !== o) {
                if ((n = er()) !== o) {
                  if (lr() !== o) {
                    if (44 === t.charCodeAt($t) ? (i = g, $t++) : (i = o, 0 === Zt && Qt(v)), i !== o) {
                      if (lr() !== o) {
                        if ($t, t.substr($t, 6) === w ? (u = w, $t += 6) : (u = o, 0 === Zt && Qt(b)), u !== o && (u = S(n, u)), u !== o) {
                          if ((u = lr()) !== o) {
                            if (44 === t.charCodeAt($t) ? (a = g, $t++) : (a = o, 0 === Zt && Qt(v)), a !== o) {
                              if (lr() !== o) {
                                if (c = [], (h = nr()) !== o) for (; h !== o;) c.push(h), h = nr();else c = o;
                                c !== o && (h = lr()) !== o ? (125 === t.charCodeAt($t) ? (f = m, $t++) : (f = o, 0 === Zt && Qt(d)), f !== o ? (e = k(n, c), r = e) : ($t = r, r = o)) : ($t = r, r = o);
                              } else $t = r, r = o;
                            } else $t = r, r = o;
                          } else $t = r, r = o;
                        } else $t = r, r = o;
                      } else $t = r, r = o;
                    } else $t = r, r = o;
                  } else $t = r, r = o;
                } else $t = r, r = o;
              } else $t = r, r = o;
            } else $t = r, r = o;
            return r;
          }()) === o && (r = function () {
            var r, e, n, i, u, a, c, h, f, s, y;
            if (r = $t, 123 === t.charCodeAt($t) ? (e = l, $t++) : (e = o, 0 === Zt && Qt(p)), e !== o) {
              if (lr() !== o) {
                if ((n = er()) !== o) {
                  if (lr() !== o) {
                    if (44 === t.charCodeAt($t) ? (i = g, $t++) : (i = o, 0 === Zt && Qt(v)), i !== o) {
                      if (lr() !== o) {
                        if (u = $t, t.substr($t, 6) === x ? (a = x, $t += 6) : (a = o, 0 === Zt && Qt(A)), a === o && (t.substr($t, 13) === j ? (a = j, $t += 13) : (a = o, 0 === Zt && Qt(N))), a !== o && (a = C(n, a)), (u = a) !== o) {
                          if ((a = lr()) !== o) {
                            if (44 === t.charCodeAt($t) ? (c = g, $t++) : (c = o, 0 === Zt && Qt(v)), c !== o) {
                              if (lr() !== o) {
                                if ((h = function () {
                                  var r, e, n, i, u;
                                  return Zt++, r = $t, (e = lr()) !== o ? (t.substr($t, 6) === M ? (n = M, $t += 6) : (n = o, 0 === Zt && Qt(_)), n !== o && lr() !== o ? (58 === t.charCodeAt($t) ? (i = T, $t++) : (i = o, 0 === Zt && Qt(R)), i !== o && lr() !== o && (u = sr()) !== o && lr() !== o ? (e = B(u), r = e) : ($t = r, r = o)) : ($t = r, r = o)) : ($t = r, r = o), Zt--, r === o && (e = o, 0 === Zt && Qt(D)), r;
                                }()) === o && (h = null), h !== o) {
                                  if (f = [], (s = or()) !== o) for (; s !== o;) f.push(s), s = or();else f = o;
                                  f !== o && (s = lr()) !== o ? (125 === t.charCodeAt($t) ? (y = m, $t++) : (y = o, 0 === Zt && Qt(d)), y !== o ? (e = F(n, u, h, f), r = e) : ($t = r, r = o)) : ($t = r, r = o);
                                } else $t = r, r = o;
                              } else $t = r, r = o;
                            } else $t = r, r = o;
                          } else $t = r, r = o;
                        } else $t = r, r = o;
                      } else $t = r, r = o;
                    } else $t = r, r = o;
                  } else $t = r, r = o;
                } else $t = r, r = o;
              } else $t = r, r = o;
            } else $t = r, r = o;
            return r;
          }()) === o && (r = function () {
            var r, e, n, i, u, a, c;
            return r = $t, 123 === t.charCodeAt($t) ? (e = l, $t++) : (e = o, 0 === Zt && Qt(p)), e !== o && lr() !== o && (n = er()) !== o && lr() !== o ? (44 === t.charCodeAt($t) ? (i = g, $t++) : (i = o, 0 === Zt && Qt(v)), i !== o && lr() !== o && (u = function () {
              var r, e, n, i, u;
              return t.substr($t, 6) === K ? (r = K, $t += 6) : (r = o, 0 === Zt && Qt(G)), r === o && (t.substr($t, 4) === U ? (r = U, $t += 4) : (r = o, 0 === Zt && Qt(Z)), r === o && (t.substr($t, 4) === I ? (r = I, $t += 4) : (r = o, 0 === Zt && Qt(Y)), r === o && (t.substr($t, 8) === V ? (r = V, $t += 8) : (r = o, 0 === Zt && Qt(W)), r === o && (t.substr($t, 7) === H ? (r = H, $t += 7) : (r = o, 0 === Zt && Qt(Q)), r === o && (t.substr($t, 8) === X ? (r = X, $t += 8) : (r = o, 0 === Zt && Qt(tt)), r === o && (r = $t, e = $t, Zt++, t.substr($t, 6) === w ? (n = w, $t += 6) : (n = o, 0 === Zt && Qt(b)), Zt--, n === o ? e = void 0 : ($t = e, e = o), e !== o ? (n = $t, Zt++, t.substr($t, 6) === x ? (i = x, $t += 6) : (i = o, 0 === Zt && Qt(A)), Zt--, i === o ? n = void 0 : ($t = n, n = o), n !== o ? (i = $t, Zt++, t.substr($t, 13) === j ? (u = j, $t += 13) : (u = o, 0 === Zt && Qt(N)), Zt--, u === o ? i = void 0 : ($t = i, i = o), i !== o && (u = er()) !== o && (rt(u) ? void 0 : o) !== o ? (e = et(u), r = e) : ($t = r, r = o)) : ($t = r, r = o)) : ($t = r, r = o))))))), r;
            }()) !== o && lr() !== o ? ((a = function () {
              var r, e, n, i, u;
              if (r = $t, (e = lr()) !== o) {
                if (44 === t.charCodeAt($t) ? (n = g, $t++) : (n = o, 0 === Zt && Qt(v)), n !== o) {
                  for (i = [], u = rr(); u !== o;) i.push(u), u = rr();
                  i !== o && (u = (u = nt(i)) ? void 0 : o) !== o ? (e = ot(i), r = e) : ($t = r, r = o);
                } else $t = r, r = o;
              } else $t = r, r = o;
              if (r === o) if (r = $t, (e = lr()) !== o) {
                if (44 === t.charCodeAt($t) ? (n = g, $t++) : (n = o, 0 === Zt && Qt(v)), n !== o) {
                  for (i = [], u = ur(); u !== o;) i.push(u), u = ur();
                  i !== o ? (e = it(i), r = e) : ($t = r, r = o);
                } else $t = r, r = o;
              } else $t = r, r = o;
              return r;
            }()) === o && (a = null), a !== o ? (125 === t.charCodeAt($t) ? (c = m, $t++) : (c = o, 0 === Zt && Qt(d)), c !== o ? (e = O(n, u, a), r = e) : ($t = r, r = o)) : ($t = r, r = o)) : ($t = r, r = o)) : ($t = r, r = o), r;
          }()) === o && (r = $t, 35 === t.charCodeAt($t) ? (e = a, $t++) : (e = o, 0 === Zt && Qt(c)), e !== o && (n = (n = h()) ? void 0 : o) !== o ? r = e = f() : ($t = r, r = o), r === o)) {
            if (r = $t, e = [], (n = fr()) !== o) for (; n !== o;) e.push(n), n = fr();else e = o;
            e !== o && (e = s(e)), r = e;
          }
          return r;
        }
        function er() {
          var r, e, n;
          if (Zt++, r = $t, e = [], z.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(P)), n !== o) for (; n !== o;) e.push(n), z.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(P));else e = o;
          return r = e !== o ? t.substring(r, $t) : e, Zt--, r === o && (e = o, 0 === Zt && Qt(E)), r;
        }
        function nr() {
          var t, r, e;
          return t = $t, lr() !== o && (r = er()) !== o && lr() !== o && (e = ir()) !== o ? t = L(r, e) : ($t = t, t = o), t;
        }
        function or() {
          var r, e, n;
          return r = $t, lr() !== o && (e = function () {
            var r, e, n;
            return (r = er()) === o && (r = $t, 61 === t.charCodeAt($t) ? (e = q, $t++) : (e = o, 0 === Zt && Qt($)), e !== o && (n = sr()) !== o ? (e = B(n), r = e) : ($t = r, r = o)), r;
          }()) !== o && lr() !== o && (n = ir()) !== o ? r = L(e, n) : ($t = r, r = o), r;
        }
        function ir() {
          var r, e, n, i, u, a;
          if (r = $t, 123 === t.charCodeAt($t) ? (e = l, $t++) : (e = o, 0 === Zt && Qt(p)), e !== o) {
            if (n = $t, (i = lr()) !== o ? (u = $t, Zt++, 123 === t.charCodeAt($t) ? (a = l, $t++) : (a = o, 0 === Zt && Qt(p)), Zt--, a !== o ? ($t = u, u = void 0) : u = o, u !== o ? n = i = [i, u] : ($t = n, n = o)) : ($t = n, n = o), n === o && (n = null), n !== o) {
              for (i = [], u = rr(); u !== o;) i.push(u), u = rr();
              i !== o && (u = lr()) !== o ? (125 === t.charCodeAt($t) ? (a = m, $t++) : (a = o, 0 === Zt && Qt(d)), a !== o ? r = e = J(i) : ($t = r, r = o)) : ($t = r, r = o);
            } else $t = r, r = o;
          } else $t = r, r = o;
          return r;
        }
        function ur() {
          var r, e, n, i;
          if (Zt++, r = $t, e = [], at.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(ct)), n !== o) for (; n !== o;) e.push(n), at.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(ct));else e = o;
          if (e !== o && (e = ht(e)), (r = e) === o && (r = ar()) === o && (r = $t, 39 === t.charCodeAt($t) ? (e = ft, $t++) : (e = o, 0 === Zt && Qt(st)), e !== o && (n = cr()) !== o ? (39 === t.charCodeAt($t) ? (i = ft, $t++) : (i = o, 0 === Zt && Qt(st)), i !== o ? r = e = lt(n) : ($t = r, r = o)) : ($t = r, r = o), r === o)) if (r = $t, 123 === t.charCodeAt($t) ? (e = l, $t++) : (e = o, 0 === Zt && Qt(p)), e !== o) {
            for (n = [], i = ur(); i !== o;) n.push(i), i = ur();
            n !== o ? (125 === t.charCodeAt($t) ? (i = m, $t++) : (i = o, 0 === Zt && Qt(d)), i !== o ? r = e = pt(n) : ($t = r, r = o)) : ($t = r, r = o);
          } else $t = r, r = o;
          return Zt--, r === o && (e = o, 0 === Zt && Qt(ut)), r;
        }
        function ar() {
          var r, e;
          return Zt++, r = $t, t.substr($t, 2) === dt ? (e = dt, $t += 2) : (e = o, 0 === Zt && Qt(yt)), e !== o && (e = gt()), Zt--, (r = e) === o && (e = o, 0 === Zt && Qt(mt)), r;
        }
        function cr() {
          var r, e, n;
          if ((r = ar()) === o) {
            if (r = $t, e = [], vt.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(wt)), n !== o) for (; n !== o;) e.push(n), vt.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(wt));else e = o;
            e !== o && (e = s(e)), r = e;
          }
          return r;
        }
        function hr() {
          var r, e, n, i, u, a;
          if (Zt++, (r = function () {
            var r, e, n, i;
            if (r = $t, t.substr($t, 2) === bt ? (e = bt, $t += 2) : (e = o, 0 === Zt && Qt(St)), e !== o) {
              for (n = [], i = cr(); i !== o;) n.push(i), i = cr();
              n !== o ? (39 === t.charCodeAt($t) ? (i = ft, $t++) : (i = o, 0 === Zt && Qt(st)), i !== o ? r = e = kt(n) : ($t = r, r = o)) : ($t = r, r = o);
            } else $t = r, r = o;
            if (r === o) if (r = $t, t.substr($t, 2) === xt ? (e = xt, $t += 2) : (e = o, 0 === Zt && Qt(At)), e !== o) {
              for (n = [], i = cr(); i !== o;) n.push(i), i = cr();
              n !== o ? (39 === t.charCodeAt($t) ? (i = ft, $t++) : (i = o, 0 === Zt && Qt(st)), i !== o ? r = e = jt(n) : ($t = r, r = o)) : ($t = r, r = o);
            } else $t = r, r = o;
            return r;
          }()) === o) {
            if (r = $t, e = $t, n = $t, t.substr($t, 2) === Ct ? (i = Ct, $t += 2) : (i = o, 0 === Zt && Qt(Ft)), i !== o) {
              for (u = [], a = cr(); a !== o;) u.push(a), a = cr();
              u !== o ? (39 === t.charCodeAt($t) ? (a = ft, $t++) : (a = o, 0 === Zt && Qt(st)), a !== o ? n = i = Ot(u) : ($t = n, n = o)) : ($t = n, n = o);
            } else $t = n, n = o;
            n !== o && (i = (i = h()) ? void 0 : o) !== o ? e = n = [n, i] : ($t = e, e = o), e !== o && (e = Et(e)), (r = e) === o && (39 === t.charCodeAt($t) ? (r = ft, $t++) : (r = o, 0 === Zt && Qt(st)));
          }
          return Zt--, r === o && (e = o, 0 === Zt && Qt(Nt)), r;
        }
        function fr() {
          var r, e;
          return (r = ar()) === o && (r = hr()) === o && (r = $t, 35 === t.charCodeAt($t) ? (e = a, $t++) : (e = o, 0 === Zt && Qt(c)), e !== o && (Jt(e) ? void 0 : o) !== o ? r = e = Dt(e) : ($t = r, r = o), r === o && (r = function () {
            var r;
            return Zt++, Pt.test(t.charAt($t)) ? (r = t.charAt($t), $t++) : (r = o, 0 === Zt && Qt(Lt)), Zt--, r === o && 0 === Zt && Qt(zt), r;
          }())), r;
        }
        function sr() {
          var r, e, n;
          if (Zt++, r = $t, e = [], _t.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(Tt)), n !== o) for (; n !== o;) e.push(n), _t.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(Tt));else e = o;
          return r = e !== o ? t.substring(r, $t) : e, Zt--, r === o && (e = o, 0 === Zt && Qt(Mt)), r;
        }
        function lr() {
          var r, e, n;
          for (Zt++, r = $t, e = [], Bt.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(qt)); n !== o;) e.push(n), Bt.test(t.charAt($t)) ? (n = t.charAt($t), $t++) : (n = o, 0 === Zt && Qt(qt));
          return r = e !== o ? t.substring(r, $t) : e, Zt--, r === o && (e = o, 0 === Zt && Qt(Rt)), r;
        }
        var pr = [!1];
        if ((e = u()) !== o && $t === t.length) return e;
        throw e !== o && $t < t.length && Qt({
          type: "end"
        }), Xt(Ut, Gt < t.length ? t.charAt(Gt) : null, Gt < t.length ? Ht(Gt, Gt + 1) : Ht(Gt, Gt));
      }
    };
  }, function (t, r, e) {
    var n,
      o,
      i = [{
        cardinal: ["other"],
        ordinal: ["other"]
      }, {
        cardinal: ["one", "other"],
        ordinal: ["other"]
      }, {
        cardinal: ["one", "other"],
        ordinal: ["one", "other"]
      }, {
        cardinal: ["one", "two", "other"],
        ordinal: ["other"]
      }];
    void 0 === (o = "function" == typeof (n = {
      af: i[1],
      ak: i[1],
      am: i[1],
      ar: {
        cardinal: ["zero", "one", "two", "few", "many", "other"],
        ordinal: ["other"]
      },
      ars: {
        cardinal: ["zero", "one", "two", "few", "many", "other"],
        ordinal: ["other"]
      },
      as: {
        cardinal: ["one", "other"],
        ordinal: ["one", "two", "few", "many", "other"]
      },
      asa: i[1],
      ast: i[1],
      az: {
        cardinal: ["one", "other"],
        ordinal: ["one", "few", "many", "other"]
      },
      be: {
        cardinal: ["one", "few", "many", "other"],
        ordinal: ["few", "other"]
      },
      bem: i[1],
      bez: i[1],
      bg: i[1],
      bh: i[1],
      bm: i[0],
      bn: {
        cardinal: ["one", "other"],
        ordinal: ["one", "two", "few", "many", "other"]
      },
      bo: i[0],
      br: {
        cardinal: ["one", "two", "few", "many", "other"],
        ordinal: ["other"]
      },
      brx: i[1],
      bs: {
        cardinal: ["one", "few", "other"],
        ordinal: ["other"]
      },
      ca: {
        cardinal: ["one", "other"],
        ordinal: ["one", "two", "few", "other"]
      },
      ce: i[1],
      cgg: i[1],
      chr: i[1],
      ckb: i[1],
      cs: {
        cardinal: ["one", "few", "many", "other"],
        ordinal: ["other"]
      },
      cy: {
        cardinal: ["zero", "one", "two", "few", "many", "other"],
        ordinal: ["zero", "one", "two", "few", "many", "other"]
      },
      da: i[1],
      de: i[1],
      dsb: {
        cardinal: ["one", "two", "few", "other"],
        ordinal: ["other"]
      },
      dv: i[1],
      dz: i[0],
      ee: i[1],
      el: i[1],
      en: {
        cardinal: ["one", "other"],
        ordinal: ["one", "two", "few", "other"]
      },
      eo: i[1],
      es: i[1],
      et: i[1],
      eu: i[1],
      fa: i[1],
      ff: i[1],
      fi: i[1],
      fil: i[2],
      fo: i[1],
      fr: i[2],
      fur: i[1],
      fy: i[1],
      ga: {
        cardinal: ["one", "two", "few", "many", "other"],
        ordinal: ["one", "other"]
      },
      gd: {
        cardinal: ["one", "two", "few", "other"],
        ordinal: ["one", "two", "few", "other"]
      },
      gl: i[1],
      gsw: i[1],
      gu: {
        cardinal: ["one", "other"],
        ordinal: ["one", "two", "few", "many", "other"]
      },
      guw: i[1],
      gv: {
        cardinal: ["one", "two", "few", "many", "other"],
        ordinal: ["other"]
      },
      ha: i[1],
      haw: i[1],
      he: {
        cardinal: ["one", "two", "many", "other"],
        ordinal: ["other"]
      },
      hi: {
        cardinal: ["one", "other"],
        ordinal: ["one", "two", "few", "many", "other"]
      },
      hr: {
        cardinal: ["one", "few", "other"],
        ordinal: ["other"]
      },
      hsb: {
        cardinal: ["one", "two", "few", "other"],
        ordinal: ["other"]
      },
      hu: i[2],
      hy: i[2],
      ia: i[1],
      id: i[0],
      ig: i[0],
      ii: i[0],
      in: i[0],
      io: i[1],
      is: i[1],
      it: {
        cardinal: ["one", "other"],
        ordinal: ["many", "other"]
      },
      iu: i[3],
      iw: {
        cardinal: ["one", "two", "many", "other"],
        ordinal: ["other"]
      },
      ja: i[0],
      jbo: i[0],
      jgo: i[1],
      ji: i[1],
      jmc: i[1],
      jv: i[0],
      jw: i[0],
      ka: {
        cardinal: ["one", "other"],
        ordinal: ["one", "many", "other"]
      },
      kab: i[1],
      kaj: i[1],
      kcg: i[1],
      kde: i[0],
      kea: i[0],
      kk: {
        cardinal: ["one", "other"],
        ordinal: ["many", "other"]
      },
      kkj: i[1],
      kl: i[1],
      km: i[0],
      kn: i[1],
      ko: i[0],
      ks: i[1],
      ksb: i[1],
      ksh: {
        cardinal: ["zero", "one", "other"],
        ordinal: ["other"]
      },
      ku: i[1],
      kw: i[3],
      ky: i[1],
      lag: {
        cardinal: ["zero", "one", "other"],
        ordinal: ["other"]
      },
      lb: i[1],
      lg: i[1],
      lkt: i[0],
      ln: i[1],
      lo: {
        cardinal: ["other"],
        ordinal: ["one", "other"]
      },
      lt: {
        cardinal: ["one", "few", "many", "other"],
        ordinal: ["other"]
      },
      lv: {
        cardinal: ["zero", "one", "other"],
        ordinal: ["other"]
      },
      mas: i[1],
      mg: i[1],
      mgo: i[1],
      mk: {
        cardinal: ["one", "other"],
        ordinal: ["one", "two", "many", "other"]
      },
      ml: i[1],
      mn: i[1],
      mo: {
        cardinal: ["one", "few", "other"],
        ordinal: ["one", "other"]
      },
      mr: {
        cardinal: ["one", "other"],
        ordinal: ["one", "two", "few", "other"]
      },
      ms: {
        cardinal: ["other"],
        ordinal: ["one", "other"]
      },
      mt: {
        cardinal: ["one", "few", "many", "other"],
        ordinal: ["other"]
      },
      my: i[0],
      nah: i[1],
      naq: i[3],
      nb: i[1],
      nd: i[1],
      ne: i[2],
      nl: i[1],
      nn: i[1],
      nnh: i[1],
      no: i[1],
      nqo: i[0],
      nr: i[1],
      nso: i[1],
      ny: i[1],
      nyn: i[1],
      om: i[1],
      or: {
        cardinal: ["one", "other"],
        ordinal: ["one", "two", "few", "many", "other"]
      },
      os: i[1],
      pa: i[1],
      pap: i[1],
      pl: {
        cardinal: ["one", "few", "many", "other"],
        ordinal: ["other"]
      },
      prg: {
        cardinal: ["zero", "one", "other"],
        ordinal: ["other"]
      },
      ps: i[1],
      pt: i[1],
      "pt-PT": i[1],
      rm: i[1],
      ro: {
        cardinal: ["one", "few", "other"],
        ordinal: ["one", "other"]
      },
      rof: i[1],
      root: i[0],
      ru: {
        cardinal: ["one", "few", "many", "other"],
        ordinal: ["other"]
      },
      rwk: i[1],
      sah: i[0],
      saq: i[1],
      sc: {
        cardinal: ["one", "other"],
        ordinal: ["many", "other"]
      },
      scn: {
        cardinal: ["one", "other"],
        ordinal: ["many", "other"]
      },
      sd: i[1],
      sdh: i[1],
      se: i[3],
      seh: i[1],
      ses: i[0],
      sg: i[0],
      sh: {
        cardinal: ["one", "few", "other"],
        ordinal: ["other"]
      },
      shi: {
        cardinal: ["one", "few", "other"],
        ordinal: ["other"]
      },
      si: i[1],
      sk: {
        cardinal: ["one", "few", "many", "other"],
        ordinal: ["other"]
      },
      sl: {
        cardinal: ["one", "two", "few", "other"],
        ordinal: ["other"]
      },
      sma: i[3],
      smi: i[3],
      smj: i[3],
      smn: i[3],
      sms: i[3],
      sn: i[1],
      so: i[1],
      sq: {
        cardinal: ["one", "other"],
        ordinal: ["one", "many", "other"]
      },
      sr: {
        cardinal: ["one", "few", "other"],
        ordinal: ["other"]
      },
      ss: i[1],
      ssy: i[1],
      st: i[1],
      sv: i[2],
      sw: i[1],
      syr: i[1],
      ta: i[1],
      te: i[1],
      teo: i[1],
      th: i[0],
      ti: i[1],
      tig: i[1],
      tk: {
        cardinal: ["one", "other"],
        ordinal: ["few", "other"]
      },
      tl: i[2],
      tn: i[1],
      to: i[0],
      tr: i[1],
      ts: i[1],
      tzm: i[1],
      ug: i[1],
      uk: {
        cardinal: ["one", "few", "many", "other"],
        ordinal: ["few", "other"]
      },
      ur: i[1],
      uz: i[1],
      ve: i[1],
      vi: {
        cardinal: ["other"],
        ordinal: ["one", "other"]
      },
      vo: i[1],
      vun: i[1],
      wa: i[1],
      wae: i[1],
      wo: i[0],
      xh: i[1],
      xog: i[1],
      yi: i[1],
      yo: i[0],
      yue: i[0],
      zh: i[0],
      zu: i[1]
    }) ? n.call(r, e, r, t) : n) || (t.exports = o);
  }, function (t, r) {
    function e(t, r, e) {
      var n = {
        day: "numeric",
        month: "short",
        year: "numeric"
      };
      switch (e) {
        case "full":
          n.weekday = "long";
        case "long":
          n.month = "long";
          break;
        case "short":
          n.month = "numeric";
      }
      return new Date(t).toLocaleDateString(r, n);
    }
    t.exports = function () {
      return e;
    };
  }, function (t, r) {
    function e(t) {
      if (!isFinite(t)) return String(t);
      var r = "";
      t < 0 ? (r = "-", t = Math.abs(t)) : t = Number(t);
      var e = t % 60,
        n = [Math.round(e) === e ? e : e.toFixed(3)];
      return t < 60 ? n.unshift(0) : (t = Math.round((t - n[0]) / 60), n.unshift(t % 60), t >= 60 && (t = Math.round((t - n[0]) / 60), n.unshift(t))), r + n.shift() + ":" + n.map(function (t) {
        return t < 10 ? "0" + String(t) : String(t);
      }).join(":");
    }
    t.exports = function () {
      return e;
    };
  }, function (t, r) {
    t.exports = function (t) {
      var r = function (t, r, e) {
        var n = e && e.split(":") || [],
          o = {
            integer: {
              maximumFractionDigits: 0
            },
            percent: {
              style: "percent"
            },
            currency: {
              style: "currency",
              currency: n[1] && n[1].trim() || CURRENCY,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          };
        return new Intl.NumberFormat(r, o[n[0]] || {}).format(t);
      }.toString().replace("CURRENCY", JSON.stringify(t.currency || "USD")).match(/\(([^)]*)\)[^{]*{([\s\S]*)}/);
      return new Function(r[1], r[2]);
    };
  }, function (t, r) {
    function e(t, r, e) {
      var n = {
        second: "numeric",
        minute: "numeric",
        hour: "numeric"
      };
      switch (e) {
        case "full":
        case "long":
          n.timeZoneName = "short";
          break;
        case "short":
          delete n.second;
      }
      return new Date(t).toLocaleTimeString(r, n);
    }
    t.exports = function () {
      return e;
    };
  }, function (t, r, e) {
    "use strict";

    e.r(r);
    var n = e(1),
      o = e.n(n),
      i = e(2),
      u = {
        break: !0,
        continue: !0,
        delete: !0,
        else: !0,
        for: !0,
        function: !0,
        if: !0,
        in: !0,
        new: !0,
        return: !0,
        this: !0,
        typeof: !0,
        var: !0,
        void: !0,
        while: !0,
        with: !0,
        case: !0,
        catch: !0,
        default: !0,
        do: !0,
        finally: !0,
        instanceof: !0,
        switch: !0,
        throw: !0,
        try: !0
      },
      a = {
        debugger: !0,
        class: !0,
        enum: !0,
        extends: !0,
        super: !0,
        const: !0,
        export: !0,
        import: !0,
        null: !0,
        true: !0,
        false: !0,
        implements: !0,
        let: !0,
        private: !0,
        public: !0,
        yield: !0,
        interface: !0,
        package: !0,
        protected: !0,
        static: !0
      };
    function c(t, r) {
      if (/^[A-Z_$][0-9A-Z_$]*$/i.test(t) && !u[t]) return r ? "".concat(r, ".").concat(t) : t;
      var e = JSON.stringify(t);
      return r ? r + "[".concat(e, "]") : e;
    }
    function h(t) {
      var r = t.trim().replace(/\W+/g, "_");
      return u[r] || a[r] || /^\d/.test(r) ? "_" + r : r;
    }
    var f = new RegExp("^" + ["ar", "ckb", "fa", "he", "ks($|[^bfh])", "lrc", "mzn", "pa-Arab", "ps", "ug", "ur", "uz-Arab", "yi"].join("|^"));
    function s(t) {
      return (s = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    function l(t, r) {
      for (var e = 0; e < r.length; e++) {
        var n = r[e];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
      }
    }
    var p = function () {
        function t(r) {
          !function (t, r) {
            if (!(t instanceof r)) throw new TypeError("Cannot call a class as a function");
          }(this, t), this.mf = r, this.lc = null, this.locales = {}, this.runtime = {}, this.formatters = {};
        }
        var r, e, n;
        return r = t, (e = [{
          key: "compile",
          value: function (t, r, e) {
            var n = this;
            if ("object" != s(t)) {
              this.lc = r;
              var o = e[r] || {
                cardinal: [],
                ordinal: []
              };
              o.strict = !!this.mf.options.strictNumberSign;
              var u = Object(i.parse)(t, o).map(function (t) {
                return n.token(t);
              });
              return "function(d) { return ".concat(u.join(" + ") || '""', "; }");
            }
            var a = {};
            for (var c in t) {
              var h = e.hasOwnProperty(c) ? c : r;
              a[c] = this.compile(t[c], h, e);
            }
            return a;
          }
        }, {
          key: "cases",
          value: function (t, r) {
            var e = this,
              n = "select" === t.type || !this.mf.hasCustomPluralFuncs,
              o = t.cases.map(function (t) {
                var o = t.key,
                  i = t.tokens;
                "other" === o && (n = !1);
                var u = i.map(function (t) {
                  return e.token(t, r);
                });
                return c(o) + ": " + (u.join(" + ") || '""');
              });
            if (n) throw new Error("No 'other' form found in " + JSON.stringify(t));
            return "{ ".concat(o.join(", "), " }");
          }
        }, {
          key: "token",
          value: function (t, r) {
            var e,
              n = this;
            if ("string" == typeof t) return JSON.stringify(t);
            var o,
              i,
              u,
              a,
              s = [c(t.arg, "d")];
            switch (t.type) {
              case "argument":
                return this.mf.options.biDiSupport ? (o = s[0], i = this.lc, u = f.test(i), a = JSON.stringify(u ? "‏" : "‎"), "".concat(a, " + ").concat(o, " + ").concat(a)) : s[0];
              case "select":
                e = "select", r && this.mf.options.strictNumberSign && (r = null), s.push(this.cases(t, r)), this.runtime.select = !0;
                break;
              case "selectordinal":
                e = "plural", s.push(0, h(this.lc), this.cases(t, t), 1), this.locales[this.lc] = !0, this.runtime.plural = !0;
                break;
              case "plural":
                e = "plural", s.push(t.offset || 0, h(this.lc), this.cases(t, t)), this.locales[this.lc] = !0, this.runtime.plural = !0;
                break;
              case "function":
                if (!(t.key in this.mf.fmt) && t.key in this.mf.constructor.formatters) {
                  var l = this.mf.constructor.formatters[t.key];
                  this.mf.fmt[t.key] = l(this.mf);
                }
                if (!this.mf.fmt[t.key]) throw new Error("Formatting function ".concat(JSON.stringify(t.key), " not found!"));
                if (s.push(JSON.stringify(this.lc)), t.param) {
                  r && this.mf.options.strictNumberSign && (r = null);
                  var p = t.param.tokens.map(function (t) {
                    return n.token(t, r);
                  });
                  s.push("(" + (p.join(" + ") || '""') + ").trim()");
                }
                e = c(t.key, "fmt"), this.formatters[t.key] = !0;
                break;
              case "octothorpe":
                if (!r) return '"#"';
                e = "number", s = [c(r.arg, "d"), JSON.stringify(r.arg)], r.offset && s.push(r.offset), this.runtime.number = !0;
            }
            if (!e) throw new Error("Parser error for token " + JSON.stringify(t));
            return "".concat(e, "(").concat(s.join(", "), ")");
          }
        }]) && l(r.prototype, e), n && l(r, n), t;
      }(),
      m = e(3),
      d = e.n(m),
      y = e(0),
      g = e.n(y);
    function v(t, r, e) {
      var n = function () {
        return r.apply(this, arguments);
      };
      if (n.toString = function () {
        return r.toString();
      }, e) {
        var o = d.a[t] || {};
        n.cardinal = o.cardinal, n.ordinal = o.ordinal;
      } else n.cardinal = [], n.ordinal = [];
      return n;
    }
    function w(t, r) {
      for (var e = r.pluralKeyChecks, n = String(t); n; n = n.replace(/[-_]?[^-_]*$/, "")) {
        var o = g.a[n];
        if (o) return v(n, o, e);
      }
      throw new Error("Localisation function not found for locale " + JSON.stringify(t));
    }
    function b(t) {
      return (b = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    function S(t, r) {
      for (var e = 0; e < r.length; e++) {
        var n = r[e];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
      }
    }
    var k = function () {
      function t(r) {
        !function (t, r) {
          if (!(t instanceof r)) throw new TypeError("Cannot call a class as a function");
        }(this, t), this.plural = function (t, r, e, n, o) {
          if ({}.hasOwnProperty.call(n, t)) return n[t];
          r && (t -= r);
          var i = e(t, o);
          return i in n ? n[i] : n.other;
        }, this.select = function (t, r) {
          return {}.hasOwnProperty.call(r, t) ? r[t] : r.other;
        }, this.mf = r, this.setStrictNumber(r.options.strictNumberSign);
      }
      var r, e, n;
      return r = t, (e = [{
        key: "setStrictNumber",
        value: function (r) {
          this.number = r ? t.strictNumber : t.defaultNumber;
        }
      }, {
        key: "toString",
        value: function (t, r) {
          for (var e = {}, n = Object.keys(r.locales), o = 0; o < n.length; ++o) {
            var i = n[o];
            e[h(i)] = t[i];
          }
          for (var u = Object.keys(r.runtime), a = 0; a < u.length; ++a) {
            var f = u[a];
            e[f] = this[f];
          }
          var s = Object.keys(r.formatters);
          if (s.length > 0) {
            e.fmt = {};
            for (var l = 0; l < s.length; ++l) {
              var p = s[l];
              e.fmt[p] = this.mf.fmt[p];
            }
          }
          return function t(r, e) {
            if ("object" != b(r)) {
              var n = r.toString().replace(/^(function )\w*/, "$1"),
                o = /([ \t]*)\S.*$/.exec(n);
              return o ? n.replace(new RegExp("^" + o[1], "mg"), "") : n;
            }
            var i = [];
            for (var u in r) {
              var a = t(r[u], e + 1);
              i.push(0 === e ? "var ".concat(u, " = ").concat(a, ";\n") : "".concat(c(u), ": ").concat(a));
            }
            if (0 === e) return i.join("");
            if (0 === i.length) return "{}";
            for (var h = "  "; --e;) h += "  ";
            var f = i.join(",\n").replace(/^/gm, h);
            return "{\n".concat(f, "\n}");
          }(e, 0);
        }
      }]) && S(r.prototype, e), n && S(r, n), t;
    }();
    function x(t) {
      return (x = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
        return typeof t;
      } : function (t) {
        return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
      })(t);
    }
    function A(t, r) {
      for (var e = 0; e < r.length; e++) {
        var n = r[e];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
      }
    }
    function j(t, r, e) {
      return r && A(t.prototype, r), e && A(t, e), t;
    }
    k.defaultNumber = function (t, r, e) {
      if (!e) return t;
      if (isNaN(t)) throw new Error("Can't apply offset:" + e + " to argument `" + r + "` with non-numerical value " + JSON.stringify(t) + ".");
      return t - e;
    }, k.strictNumber = function (t, r, e) {
      if (isNaN(t)) throw new Error("Argument `" + r + "` has non-numerical value " + JSON.stringify(t) + ".");
      return t - (e || 0);
    }, e.d(r, "default", function () {
      return N;
    });
    var N = function () {
      function t(r, e) {
        var n = this;
        if (function (t, r) {
          if (!(t instanceof r)) throw new TypeError("Cannot call a class as a function");
        }(this, t), this.options = Object.assign({
          biDiSupport: !1,
          customFormatters: null,
          pluralKeyChecks: !0,
          strictNumberSign: !1
        }, e), this.pluralFuncs = {}, "string" == typeof r) this.pluralFuncs[r] = w(r, this.options), this.defaultLocale = r;else if (Array.isArray(r)) r.forEach(function (t) {
          n.pluralFuncs[t] = w(t, n.options);
        }), this.defaultLocale = r[0];else {
          if (r) for (var o = Object.keys(r), i = 0; i < o.length; ++i) {
            var u = o[i];
            if ("function" != typeof r[u]) {
              var a = "Expected function value for locale " + String(u);
              throw new Error(a);
            }
            this.pluralFuncs[u] = r[u], this.defaultLocale || (this.defaultLocale = u);
          }
          this.defaultLocale ? this.hasCustomPluralFuncs = !0 : (this.defaultLocale = t.defaultLocale, this.hasCustomPluralFuncs = !1);
        }
        this.fmt = Object.assign({}, this.options.customFormatters), this.runtime = new k(this);
      }
      return j(t, null, [{
        key: "escape",
        value: function (t, r) {
          var e = r ? /[#{}]/g : /[{}]/g;
          return String(t).replace(e, "'$&'");
        }
      }]), j(t, [{
        key: "addFormatters",
        value: function (t) {
          for (var r = Object.keys(t), e = 0; e < r.length; ++e) {
            var n = r[e];
            this.fmt[n] = t[n];
          }
          return this;
        }
      }, {
        key: "disablePluralKeyChecks",
        value: function () {
          for (var t in this.options.pluralKeyChecks = !1, this.pluralFuncs) {
            var r = this.pluralFuncs[t];
            r && (r.cardinal = [], r.ordinal = []);
          }
          return this;
        }
      }, {
        key: "setBiDiSupport",
        value: function (t) {
          return this.options.biDiSupport = !!t || void 0 === t, this;
        }
      }, {
        key: "setStrictNumberSign",
        value: function (t) {
          return this.options.strictNumberSign = !!t || void 0 === t, this.runtime.setStrictNumber(this.options.strictNumberSign), this;
        }
      }, {
        key: "compile",
        value: function (t, r) {
          var e = {};
          if (0 === Object.keys(this.pluralFuncs).length) {
            if (r) {
              var n = w(r, this.options);
              if (!n) {
                var o = JSON.stringify(r);
                throw new Error("Locale ".concat(o, " not found!"));
              }
              e[r] = n;
            } else r = this.defaultLocale, e = function (t) {
              for (var r = t.pluralKeyChecks, e = {}, n = Object.keys(g.a), o = 0; o < n.length; ++o) {
                var i = n[o];
                e[i] = v(i, g.a[i], r);
              }
              return e;
            }(this.options);
          } else if (r) {
            var i = this.pluralFuncs[r];
            if (!i) {
              var u = JSON.stringify(r),
                a = JSON.stringify(this.pluralFuncs);
              throw new Error("Locale ".concat(u, " not found in ").concat(a, "!"));
            }
            e[r] = i;
          } else r = this.defaultLocale, e = this.pluralFuncs;
          var f = new p(this),
            s = f.compile(t, r, e);
          if ("object" != x(t)) {
            var l = new Function("number, plural, select, fmt", h(r), "return " + s),
              m = this.runtime;
            return l(m.number, m.plural, m.select, this.fmt, e[r]);
          }
          var d = this.runtime.toString(e, f) + "\n",
            y = function t(r, e) {
              if (e || (e = 0), "object" != x(r)) return r;
              for (var n = "", o = 0; o < e; ++o) n += "  ";
              var i = [];
              for (var u in r) {
                var a = t(r[u], e + 1);
                i.push("\n".concat(n, "  ").concat(c(u), ": ").concat(a));
              }
              return "{".concat(i.join(","), "\n").concat(n, "}");
            }(s),
            b = new Function(d + "return " + y)();
          if (b.hasOwnProperty("toString")) throw new Error("The top-level message key `toString` is reserved");
          return b.toString = function (t) {
            return t && "export default" !== t ? t.indexOf(".") > -1 ? d + t + " = " + y : d + ["(function (root, G) {", '  if (typeof define === "function" && define.amd) { define(G); }', '  else if (typeof exports === "object") { module.exports = G; }', "  else { " + c(t, "root") + " = G; }", "})(this, " + y + ");"].join("\n") : d + "export default " + y;
          }, b;
        }
      }]), t;
    }();
    N.defaultLocale = "en", N.formatters = o.a;
  }]).default;
});
